var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('node-view-wrapper', {
    staticClass: "custom-section"
  }, [_c('div', {
    staticClass: "custom-section__counter",
    attrs: {
      "draggable": "true",
      "data-drag-handle": ""
    }
  }, [_vm._v("§")]), _c('node-view-content', {
    staticClass: "custom-section__title"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }