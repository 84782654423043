var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('node-view-wrapper', {
    staticClass: "custom-title"
  }, [_c('node-view-content')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }