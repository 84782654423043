import { VueRenderer } from "@tiptap/vue-2";
import tippy from "tippy.js";
import store from "@/store/rootStore";
import TagList from './TagList.vue';

export default {
  items: ({ query }: any) => {
    const items = store.getters['genprox/documentDetails'];
    return items.filter((item: any) => item.toLowerCase().startsWith(query.toLowerCase()))
  },
  render: () => {
    let component: any;
    let popup: any;

    return {
      onStart: (props: any) => {
        component = new VueRenderer(TagList, {
          parent: this,
          propsData: props,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },
      onUpdate(props: any) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },
      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },
      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
  allowSpaces: true,
}