var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "items"
  }, [_vm.items.length ? _vm._l(_vm.items, function (item, index) {
    return _c('button', {
      key: index,
      staticClass: "item",
      class: {
        'is-selected': index === _vm.selectedIndex
      },
      on: {
        "click": function click($event) {
          return _vm.selectItem(index);
        }
      }
    }, [_vm._v(_vm._s(item))]);
  }) : _c('div', {
    staticClass: "item"
  }, [_vm._v(" No result ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }