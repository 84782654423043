// import { Node } from '@tiptap/core';
// import { mergeAttributes } from '@tiptap/vue-2';

// const CustomParagraphNode = Node.create({
//   name: 'customParagraphNode',
//   content: 'text*',
// });

// export default CustomParagraphNode;

import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";

import CustomTitleComponent from './CustomTitleComponent.vue';

export default Node.create({
  name: 'customTitleNode',
  group: 'block',
  inline: false,
  content: 'inline*',
  draggable: true,
  parseHTML() {
    return [
      {
        tag: 'div[data-type="custom-title-node"]'
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'custom-title-node' }), 0]
  },
  addNodeView() {
    return VueNodeViewRenderer(CustomTitleComponent);
  }
})