import { mergeAttributes, Node } from "@tiptap/core";

export default Node.create({
  name: 'customPageDivider',
  group: 'block',
  inline: false,
  content: 'text*',
  selectable: false,
  addOptions() {
    return {
      keepMarks: true,
      HTMLAttributes: {},
    }
  },
  parseHTML() {
    return [
      {
        tag: 'div[data-type="custom-page-divider"]'
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'custom-page-divider' }), 0]
  },
  addCommands(): any {
    return {
      setPageDivider: () => ({
        commands,
        chain,
        state,
        editor,
      }: any) => {
        return commands.first([
          () => commands.exitCode(),
          () => commands.command(() => {
            const { selection, storedMarks } = state

            if (selection.$from.parent.type.spec.isolating) {
              return false
            }

            const { keepMarks } = this.options
            const { splittableMarks } = editor.extensionManager
            const marks = storedMarks
              || (selection.$to.parentOffset && selection.$from.marks())

            return chain()
              .insertContent({ type: this.name })
              .command(({ tr, dispatch }: any) => {
                if (dispatch && marks && keepMarks) {
                  const filteredMarks = marks
                    .filter((mark: any) => splittableMarks.includes(mark.type.name))

                  tr.ensureMarks(filteredMarks)
                }

                return true
              })
              .run()
          }),
        ])
      },
    }
  },
})