<template>
  <node-view-wrapper class="custom-title">
    <node-view-content></node-view-content>
  </node-view-wrapper>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2';

@Component({
  components: {NodeViewWrapper, NodeViewContent},
  props: { nodeViewProps }
})

export default class CustomTitle extends Vue {
}
</script>

<style lang="scss">
  .custom-title {
    border-bottom: 2px solid var(--text-color);
    border-top: 2px solid var(--text-color);
    text-align: center;
    font-weight: 700;
  }
</style>