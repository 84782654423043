











import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator';
// import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2';

@Component({
  // components: {NodeViewWrapper, NodeViewContent},
  // props: { nodeViewProps }
})

export default class TagComponent extends Vue {
  @Prop({required: true}) command: Function;
  @Prop({}) items: Array<string>;

  selectedIndex: number = 0;

  @Watch('items') onItemsChange() {
    this.selectedIndex = 0;
  }

  // onKeyUp({ event }: any) {}

  onKeyDown({ event }: any) {
    if (event.key === 'ArrowUp') {
      this.upHandler()
      return true
    }

    if (event.key === 'ArrowDown') {
      this.downHandler()
      return true
    }

    if (event.key === 'Enter') {
      this.enterHandler()
      return true
    }

    return false
  }

  upHandler() {
    this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
  }

  downHandler() {
    this.selectedIndex = (this.selectedIndex + 1) % this.items.length
  }

  enterHandler() {
    this.selectItem(this.selectedIndex)
  }

  selectItem(index: number) {
    const item = this.items[index]

    if (item) {
      this.command({ id: item })
    }
  }
}
