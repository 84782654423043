<template>
  <node-view-wrapper class="custom-section">
    <div class="custom-section__counter" draggable="true" data-drag-handle>&sect;</div>
    <node-view-content class="custom-section__title"></node-view-content>
  </node-view-wrapper>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2';

@Component({
  components: {NodeViewWrapper, NodeViewContent},
  props: { nodeViewProps }
})

export default class CustomSection extends Vue {
}
</script>

<style lang="scss">
  .custom-section {
    text-align: center;
    font-weight: 700;

    &__counter {
      &::after {
        content: counter(section);
      }
    }
  }
</style>