import Mention from '@tiptap/extension-mention';

const CustomMention = Mention.extend({
  draggable: true,
  selectable: true,
  addAttributes() {
    return {
      id: {
        default: '',
        parseHTML: element => element.getAttribute('data-id')
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'span[data-type="custom-mention"]',
      }
    ]
  },
  renderHTML(props) {
    const { node } = props;

    return [
      "span",
      [
        "span", {
          "class": node.type.name,
          "data-type": 'custom-mention',
          "data-id": node.attrs.id,
        },
        `@${node.attrs.id}`
      ]
    ];
  }
})

export default CustomMention
